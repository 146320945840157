import React from 'react'
import { PlayCircleIcon } from '@/atoms/Icons/PlayCircleIcon'

export const PlayButtonThumbnailOverlay = () => {
  return (
    <div className="absolute inset-0">
      <div className="flex h-full w-full items-center justify-center opacity-0 duration-200 hover:bg-[#00000088] hover:opacity-100">
        <div className="mx-auto h-fit w-fit !opacity-100">
          <PlayCircleIcon />
        </div>
      </div>
    </div>
  )
}
