import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const PlayCircleIcon: React.FC<OneColorIconProps> = ({ size = 42, color = 'white', className }) => {
  return (
    <svg
      data-testid="play-circle-icon"
      className={className}
      width={size}
      height={size}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7003 0.291504C9.35319 0.291504 0.127441 9.51725 0.127441 20.8644C0.127441 32.2115 9.35319 41.4373 20.7003 41.4373C32.0474 41.4373 41.2732 32.2115 41.2732 20.8644C41.2732 9.51725 32.0474 0.291504 20.7003 0.291504ZM27.3274 21.903L17.6248 28.3572C16.8026 28.9206 15.6758 28.3133 15.6758 27.3185V14.4113C15.6758 13.4148 16.8026 12.8091 17.6248 13.3725L27.3256 19.825C28.0633 20.3004 28.0633 21.4272 27.3273 21.9026L27.3274 21.903Z"
        fill={getHexColor(color)}
      />
    </svg>
  )
}
