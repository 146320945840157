import React from 'react'
import { Case, Default, Switch } from 'react-if'
import { GuildIconSingle } from '@/atoms/Icons/GuildIconSingle'
import { CaptionXS } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'
import useWindowSize from '@/utils/useWindowSize'

interface Props {
  earlyAccessDate?: Date
  publiclyAvailableDate?: Date
  isGuildMember: boolean
}

export const GuildAccessNotch: React.FC<Props> = ({ earlyAccessDate, publiclyAvailableDate, isGuildMember }) => {
  const { t } = useTranslate('watch')
  const { innerWidth } = useWindowSize()
  const now = Date.now()
  const publiclyAvailable = publiclyAvailableDate && publiclyAvailableDate.getTime() < now
  if (
    isGuildMember ||
    !earlyAccessDate ||
    publiclyAvailable ||
    (earlyAccessDate && publiclyAvailableDate && earlyAccessDate.getTime() === publiclyAvailableDate.getTime())
  )
    return null

  const text = t('membersOnly', 'Members Only')

  return (
    <div
      className="padding-[2px] absolute -left-1 -top-1 z-[5] rounded-br-2xl p-[2px]"
      style={{
        background:
          'linear-gradient(175deg, #EDD7C6 0%, #D6A177 13.88%, #FEDEC6 40.62%, #D48D59 62.21%, #A66141 88.77%, #612B12 98.15%)',
      }}
    >
      <div
        className="flex h-full w-full gap-1 rounded-br-2xl px-3 pb-1 pt-[5px] md:px-[14px] lg:px-4"
        style={{
          background:
            'linear-gradient(152deg, #F1DFD1 0%, #DFB695 13.88%, #F3D0B6 40.62%, #DEA67D 62.21%, #B98268 88.77%, #815642 98.15%)',
        }}
      >
        <Switch>
          <Case condition={innerWidth > 1024}>
            <GuildIconSingle size={16} color="guild-copper" />
          </Case>
          <Case condition={innerWidth > 768}>
            <GuildIconSingle size={14} color="guild-copper" />
          </Case>
          <Default>
            <GuildIconSingle size={12} color="guild-copper" />
          </Default>
        </Switch>

        <CaptionXS
          className="lg:photon-caption-sm -mb-[1px] whitespace-nowrap text-right text-guild-copper"
          weight="medium"
          color="guild-copper"
          style={{
            backfaceVisibility: 'hidden',
            willChange: 'transform',
            WebkitFontSmoothing: 'subpixel-antialiased',
          }}
        >
          {text}
        </CaptionXS>
      </div>
    </div>
  )
}
