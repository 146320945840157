import React from 'react'
import { EyebrowMD } from '@/atoms/Text'
import { Paragraph, ParagraphSM } from '@/atoms/Typography'

type MediaDesciptorsProps = {
  title?: string
  subtitle?: string
  displayDescription?: boolean
  description?: string
  href: string
}

export const MediaDescriptors: React.FC<MediaDesciptorsProps> = ({
  title,
  subtitle,
  displayDescription,
  description,
  href,
}) => {
  return (
    <>
      {title && (
        <EyebrowMD
          weight="bold"
          color="core-gray-600"
          className="block overflow-x-hidden text-ellipsis whitespace-nowrap"
        >
          {title}
        </EyebrowMD>
      )}
      {subtitle && (
        <Paragraph
          as={(p) => <h3 {...p} />}
          aria-label={title}
          className="my-1 overflow-x-hidden text-ellipsis whitespace-nowrap"
        >
          {subtitle}
        </Paragraph>
      )}
      {displayDescription && description && (
        <ParagraphSM
          aria-label={href.includes('/community/stories') ? 'video-story-description' : 'episode-description'}
          className="line-clamp-4 text-gray-500"
        >
          {description}
        </ParagraphSM>
      )}
    </>
  )
}
