import React from 'react'
import classNames from 'classnames'

interface Props {
  className?: string
  borderRadiusClassName?: string
  hideTextPlaceholders?: boolean
  orientation?: 'portrait' | 'landscape'
}

export const PlaceholderMediaCard = React.forwardRef<HTMLDivElement, Props>(
  ({ className = '', hideTextPlaceholders, borderRadiusClassName, orientation = 'landscape' }, ref) => {
    return (
      <div ref={ref} className={classNames('relative group animate-pulse', className)}>
        <div
          className={classNames(
            'w-full mb-1 bg-black',
            { 'pt-[56.25%]': orientation === 'landscape', 'pt-[160%]': orientation === 'portrait' },
            borderRadiusClassName,
          )}
        />
        {!hideTextPlaceholders && (
          <>
            <div className="h-[16.25px] w-1/2 rounded bg-black" />
            <div className="my-1 h-[24px] w-3/4 rounded bg-black" />
          </>
        )}
      </div>
    )
  },
)

PlaceholderMediaCard.displayName = 'PlaceholderMediaCard'
