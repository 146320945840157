import React from 'react'

type ProgressOverlayProps = {
  progress: number
}

export const ProgressOverlay: React.FC<ProgressOverlayProps> = ({ progress }) => {
  if (progress === 0) return null

  return (
    <div className="absolute bottom-0 mx-auto flex h-1/4 w-full items-center justify-center rounded-lg bg-black-transparent-t-t">
      <div aria-label="episode-bottom-bar" className="absolute inset-x-2 bottom-2 h-1 rounded-lg bg-gray-800" />
      <div
        aria-label="episode-bottom-bar-progress"
        className="absolute inset-x-2 bottom-2 h-1 rounded-lg bg-oxide"
        style={{ width: progress >= 95 ? '95%' : `${progress}%` }}
      />
    </div>
  )
}
